import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import lego from './plugins/lego';

import 'lego-framework/src/scss/variables/lego-variables.scss';
import './assets/style.scss';

import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';

Vue.config.productionTip = false;

Vue.use(lego);

new Vue({
    router,
    store,
    render : h => h(App)
}).$mount('#app');
