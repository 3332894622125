// guard //
import guards from './guards';
// Layout //
const Layout = () => import('../layouts/MainLayout');

// Main Components //
const Home = () => import('../views/Home');
const Catalog = () => import('../views/catalog/catalog');
const Orders = () => import('../views/orders/orders');
const Customers = () => import('../views/customers/customers');
// Other Components //
const Profile = () => import('../views/userDetails/profile');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        // Main Components //
        {
            path      : '/',
            name      : 'Home',
            component : Home
        },
        {
            path      : '/catalog/',
            name      : 'Catalog',
            component : Catalog
        },
        {
            path      : '/orders/',
            name      : 'Orders',
            component : Orders
        },
        {
            path      : '/customers/',
            name      : 'Customers',
            component : Customers
        },
        // Other Components //

        {
            path      : '/profile/',
            name      : 'Profile',
            component : Profile
        }
    ]
};
