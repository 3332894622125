const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/';
}

export default {
    auth : {
        login  : baseUrl + 'auth/login/',
        status : baseUrl + 'auth/status/',
        logout : baseUrl + 'auth/logout/'
    }
};
